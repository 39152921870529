/*custom override*/
/*datepicker fix for safari*/
.datepicker {
  z-index: 1151 !important;
}

.readonly {
  background-color: rgba(145, 145, 138, 0.44) !important;
}

.has-error {
  border-color: red;
}

.edit-contract-address,
.edit-contract-address-household,
.edit-contract-mobile-phone,
.edit-horse-name,
.edit-horse-birth-date,
.edit-contract-bicycle-buy-price,
.edit-contract-bicycle-buy-date,
.edit-contract-bicycle-serial-number,
.edit-contract-bicycle-mark,
.edit-contract-bicycle-model,
.edit-contract-revenue-insured-sum {
  margin-right: 100px;
}

.upload-buy-invoice-button,
.upload-file-button {
  margin-top: 10px;
}

.delete-buy-invoice-button {
  margin-left: 10px;
}

.bicycle-buy-invoice-download {
  display: inline-block;
}

.bicycle-buy-invoice {
  padding-top: 24px;
}

.flex-right-end-div {
  display: flex;
  justify-content: flex-end;
}

.adresse-label,
.mobile-phone-label,
.horse-label,
.buy-price-label,
.buy-date-label,
.revenue-insured-sum-label {
  margin-right: auto;
}

.row-zip,
.row-city,
.row-street,
.row-housenumber,
.row-buy-price,
.row-buy-date,
.row-serial-number,
.row-mark,
.row-model {
  margin-top: 7px;
}

.hr-documents {
  margin-bottom: 0;
}

.delete-file-button {
  margin-left: 30px;
}

.logo {
  background-color: #fff !important;
}

.logo-img-lg,
.logo-img-mini {
  bottom: 3px;
  height: 55px;
  left: -5px;
  padding: 5px;
  position: relative;
  width: 200px;
}

.logo-img-mini {
  left: 4px;
}

table.dataTable tbody tr td:first-child a {
  font-weight: normal !important;
}

body.skin-blue div.wrapper-without-navigation {
  background-color: #ecf0f5;
}

.main-header .logo {
  height: 53px;
}

.without-navigation {
  margin-left: 0;
}

.stripe-announcement-text {
  font-size: 10px;
  color: grey;
}

.clickable-item {
  cursor: pointer;
  user-select: none;
}
