@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;
@import "https://fonts.googleapis.com/css?family=Lato:300,400,700,300italic,400italic";

body {
	background-color: #f2f2f2;
	font-family: "Lato";
	font-weight: 300;
	font-size: 16px;
	color: #555;

	-webkit-font-smoothing: antialiased;
	-webkit-overflow-scrolling: touch;
}

body {
	background-color: #f2f2f2;
	font-family: "Lato";
	font-weight: 300;
	font-size: 16px;
	color: #555;

	-webkit-font-smoothing: antialiased;
	-webkit-overflow-scrolling: touch;
}

body {
	background-color: #f2f2f2;
	font-family: "Lato";
	font-weight: 300;
	font-size: 16px;
	color: #555;

	-webkit-font-smoothing: antialiased;
	-webkit-overflow-scrolling: touch;
}

body, label, .checkbox label {
	font-weight: 300;
}

/* Titles */
h1, h2, h3, h4, h5, h6 {
	font-weight: 300;
}

/* Links */
a {
	word-wrap: break-word;

	-webkit-transition: color 0.1s ease-in, background 0.1s ease-in;
	-moz-transition: color 0.1s ease-in, background 0.1s ease-in;
	-ms-transition: color 0.1s ease-in, background 0.1s ease-in;
	-o-transition: color 0.1s ease-in, background 0.1s ease-in;
	transition: color 0.1s ease-in, background 0.1s ease-in;
}

a:hover,
a:focus {
	color: #c0392b;
	text-decoration: none;
	outline: 0;
}

a:before,
a:after {
	-webkit-transition: color 0.1s ease-in, background 0.1s ease-in;
	-moz-transition: color 0.1s ease-in, background 0.1s ease-in;
	-ms-transition: color 0.1s ease-in, background 0.1s ease-in;
	-o-transition: color 0.1s ease-in, background 0.1s ease-in;
	transition: color 0.1s ease-in, background 0.1s ease-in;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}


/* ==========================================================================
   Wrap Sections
   ========================================================================== */

#headerwrap {
	background-color: #34495e;
	padding-top: 60px;
}

#headerwrap h1 {
	margin-top: 30px;
	color: white;
	font-size: 70px;

}

#headerwrap h3 {
	color: white;
	font-size: 30px;
}

#headerwrap h5 {
	color: white;
	font-weight: 700;
	text-align: left;
}

#headerwrap p {
	text-align: left;
	color: white
}

/* intro Wrap */

#intro {
	padding-top: 50px;
	border-top: #bdc3c7 solid 5px;
}

#features {
	padding-top: 50px;
	padding-bottom: 50px;
}

#features .ac a{
	font-size: 20px;
}

/* Showcase Wrap */

#showcase {
	display: block;
	background-color: #34495e;
	padding-top: 50px;
	padding-bottom: 50px;
}

#showcase h1 {
	color: white;
}

#footerwrap {
	background-color: #2f2f2f;
	color: white;
	padding-top: 40px;
	padding-bottom: 60px;
	text-align: left;
}

#footerwrap h3 {
	font-size: 28px;
	color: white;
}

#footerwrap p {
	color: white;
	font-size: 18px;
}

/* Copyright Wrap */

#c {
	background: #222222;
	padding-top: 15px;
	text-align: center;
}

#c p {
	color: white
}

@import "custom";
